import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const RIASEC_PROFILE_PL_NAMES = {
  R: "Przedmiotowy",
  I: "Innowacyjny",
  A: "Kreatywny",
  S: "Społeczny",
  E: "Liderski",
  C: "Metodyczny",
};

const RIASEC_PROFILE_EN_NAMES = {
  R: "realistic",
  I: "investigative",
  A: "artistic",
  S: "social",
  E: "enterprising",
  C: "conventional",
};

const initialState = {
  isTestCompleted: null,
  riasecProfile: "",
  riasecFirstProfileName: "",
  riasecSecondProfileName: "",
  riasecScore: {},
  riasecInfo: [],
  profileWeakness: "",
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setUserCompletedTest: (state, action) => {
      localStorage.setItem("testToken", action.payload.token);
      return {
        ...state,
        isTestCompleted: action.payload.success,
      };
    },
    setUserRiasecFirstProfileName: (state, action) => {
      return {
        ...state,
        riasecFirstProfileName: action.payload,
      };
    },
    setUserRiasecSecondProfileName: (state, action) => {
      return {
        ...state,
        riasecSecondProfileName: action.payload,
      };
    },
    setUserRiasecInfo: (state, action) => {
      return {
        ...state,
        riasecProfile: action.payload.riasec_profile,
        riasecScore: action.payload.riasec_score,
      };
    },
    setRiasecInfo: (state, action) => {
      return {
        ...state,
        riasecInfo: action.payload,
      };
    },
    setProfileWeakness: (state, action) => {
      return { ...state, profileWeakness: action.payload };
    },
  },
});

export default reportSlice.reducer;

export const {
  setUserCompletedTest,
  setUserRiasecFirstProfileName,
  setUserRiasecSecondProfileName,
  setUserRiasecInfo,
  setRiasecInfo,
  setProfileWeakness,
} = reportSlice.actions;

export const getUserRiasecInfo = () => async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/users/me?populate=*`, {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
      },
    })
    .then(async (response) => {
      dispatch(setUserRiasecInfo(response.data));

      const profileName = response.data?.riasec_profile.split("");

      dispatch(
        setUserRiasecFirstProfileName(RIASEC_PROFILE_PL_NAMES[profileName[0]])
      );
      dispatch(
        setUserRiasecSecondProfileName(RIASEC_PROFILE_PL_NAMES[profileName[1]])
      );

      const profileWeakness = response.data?.weaknesses;
      dispatch(setProfileWeakness(profileWeakness));

      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/riasec-info`, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          dispatch(
            setRiasecInfo([
              response.data.data.attributes[
                RIASEC_PROFILE_EN_NAMES[profileName[0]]
              ],
              response.data.data.attributes[
                RIASEC_PROFILE_EN_NAMES[profileName[1]]
              ],
            ])
          );
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        });
    })
    .catch((error) => {
      //console.error("An error occurred:", error.response);
    });
};
