import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const CHATBOT_MODES = {
  CHAT: "chatbot",
  "Q&A": "qa",
  DISABLED: null,
};

/* The `initialState` object is defining the initial state of the user slice in the Redux store. It
contains the following properties: */
const initialState = {
  loading: false,
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  hasAccessToDashboard: false,
  isPaid: null,
  user: null,
  chatbot_mode: null,
  alertMessage: null,
  status: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLoaded: (state, action) => {
      return {
        ...state,
        isAuthenticated: action.payload.user.confirmed,
        user: action.payload.user,
      };
    },
    setUserHasAccessToDashboard: (state, action) => {
      return {
        ...state,
        hasAccessToDashboard: action.payload,
      };
    },
    setLoading: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    resetLoading: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    setUserChatbotMode: (state, action) => {
      return {
        ...state,
        chatbot_mode: action.payload,
      };
    },
    setUserIsPaid: (state, action) => {
      return {
        ...state,
        isPaid: action.payload,
      };
    },
    authError: (state) => {
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        chatbot_mode: null,
      };
    },
    loginSuccess: (state, action) => {
      localStorage.setItem("token", action.payload.jwt);
      return {
        ...state,
        token: action.payload.jwt,
        isAuthenticated: action.payload.user.confirmed,
        user: action.payload.user,
      };
    },
    logoutSuccess: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("testToken");
      localStorage.removeItem("chatbotHistory");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        chatbot_mode: null,
      };
    },
    setAlertMessage: (state, action) => {
      return {
        ...state,
        alertMessage: action.payload.message,
        status: action.payload.status,
      };
    },
  },
});

export default userSlice.reducer;

export const {
  userLoaded,
  setUserHasAccessToDashboard,
  setLoading,
  resetLoading,
  setUserChatbotMode,
  setUserIsPaid,
  authError,
  loginSuccess,
  logoutSuccess,
  setAlertMessage,
} = userSlice.actions;

export const load = () => async (dispatch) => {
  if (localStorage.token) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/users/me?populate[ab_features][populate]`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(userLoaded({ user: response.data }));
        dispatch(
          setUserChatbotMode(CHATBOT_MODES[response.data.ab_features.chat_mode])
        );
      })
      .catch((error) => {
        //console.error("An error occurred:", error.response);

        dispatch(authError());
      });
  }
};

export const login = (email, password) => async (dispatch) => {
  dispatch(setLoading());

  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/local`, {
      identifier: email,
      password: password,
    })
    .then((response) => {
      dispatch(loginSuccess(response.data));
    })
    .catch((error) => {
      //console.error("An error occurred:", error.response);

      dispatch(
        setAlertMessage({
          message: error.response.data.error.message,
          status: false,
        })
      );
    });

  dispatch(resetLoading());
};

export const resetPassword =
  (code, password, passwordConfirmation) => async (dispatch) => {
    dispatch(setLoading());

    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/auth/reset-password`, {
        code,
        password,
        passwordConfirmation,
      })
      .then(() => {
        dispatch(
          setAlertMessage({
            message:
              "Hasło zostało zmienione. Do zobaczenia kiedy zapomnisz go następnym razem ;)",
            status: true,
          })
        );
      })
      .catch((error) => {
        //console.error("An error occurred:", error.response);

        dispatch(
          setAlertMessage({
            message: error.response.data.error.message,
            status: false,
          })
        );
      });

    dispatch(resetLoading());
  };

export const changePassword =
  (currentPassword, password, passwordConfirmation) => async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/auth/change-password`,
        {
          currentPassword,
          password,
          passwordConfirmation,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then(() => {
        dispatch(
          setAlertMessage({
            message: "Hasło zostało zmienione.",
            status: true,
          })
        );
      })
      .catch((error) => {
        //console.error("An error occurred:", error.response);

        dispatch(
          setAlertMessage({
            message: error.response.data.error.message,
            status: false,
          })
        );
      });
  };

export const forgotPassword = (email) => async (dispatch) => {
  dispatch(setLoading());

  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password`, {
      email,
    })
    .then(() => {
      dispatch(
        setAlertMessage({
          message:
            "Na podany adres e-mail zostanie wysłana wiadomość z linkiem, która umożliwia zresetowanie hasła ;)",
          status: true,
        })
      );
    })
    .catch((error) => {
      //console.error("An error occurred:", error.response);

      dispatch(
        setAlertMessage({
          message: error.response.data.error.message,
          status: false,
        })
      );
    });

  dispatch(resetLoading());
};

export const sendEmailConfirmation = (email) => async (dispatch) => {
  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/send-email-confirmation`, {
      email,
    })
    .then(() => {})
    .catch((error) => {
      //console.error("An error occurred:", error.response);

      dispatch(
        setAlertMessage({
          message: error.response.data.error.message,
          status: false,
        })
      );
    });
};

export const logout = () => async (dispatch) => {
  dispatch(logoutSuccess());
};

export const register = (username, email, password) => async (dispatch) => {
  dispatch(setLoading());

  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/local/register`, {
      username: username,
      email: email,
      password: password,
    })
    .then(() => {
      dispatch(
        setAlertMessage({
          message:
            "Konto zostało pomyślnie utworzone. Sprawdź pocztę email i aktywuj konto otrzymanym linkiem",
          status: true,
        })
      );
    })
    .catch((error) => {
      //console.error("An error occurred:", error.response);

      dispatch(
        setAlertMessage({
          message: error.response.data.error.message,
          status: false,
        })
      );
    });

  dispatch(resetLoading());
};

export const authorizationAlert =
  (alertMessage, status) => async (dispatch) => {
    dispatch(setAlertMessage({ message: alertMessage, status: status }));
  };

export const checkUserHasPaid = () => async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/payment/is_paid`, {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
      },
    })
    .then((response) => {
      dispatch(setUserIsPaid(response.data.paid));
    })
    .catch((error) => {
      //console.error("An error occurred:", error.response);
    });
};

export const checkUserHasAccessToDashboard =
  () => async (dispatch, getState) => {
    const { user } = getState().user;

    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/coupons/campaign-owner/${user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          setUserHasAccessToDashboard(response.data?.length > 0 ? true : false)
        );
      })
      .catch((error) => {
        //console.error("An error occurred:", error.response);
      });
  };

export const setUserPrepaidByCode = (couponCode, uid) => async (dispatch) => {
  await axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/coupons/claim`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "application/json",
        },
        params: {
          couponCode,
          uid,
        },
      }
    )
    .then(() => {
      dispatch(
        setAlertMessage({
          message:
            "Twoje konto zostało aktywowane! Sprawdź dopasowane zawody i wybierz karierę marzeń.",
          status: true,
        })
      );
      dispatch(checkUserHasPaid());
    })
    .catch((error) => {
      //console.error("An error occurred:", error.response);

      dispatch(
        setAlertMessage({
          message: error.response.data,
          status: false,
        })
      );
    });
};
