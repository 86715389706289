import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import axios from "axios";

import { logout } from "../../store/user";

import PencilIcon from "../../assets/images/pencil_icon.png";
import BriefcaseIcon from "../../assets/images/briefcase_icon.png";

import LoadingIcon from "../../assets/images/loading-icon.gif";

import "../../styles/Loader.css";
const Loader = ({ type, content, noDataContent }) => {
  const [noDataLoaded, setNoDataLoaded] = useState(false);

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const connectTestWithUserMutation = useMutation({
    mutationFn: async () => {
      return await axios.post(
        `${process.env.REACT_APP_API_URL}/api/typeform/connect`,
        { token: localStorage.getItem("testToken") },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      );
    },
    onSuccess: () => {
      localStorage.removeItem("testToken");
      queryClient.invalidateQueries();
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setNoDataLoaded(true);
    }, 4000);
  }, []);

  const goToTest = () => {
    window.location.href = `/api/outlink?uid=${user?.id}&url=https://jobbli.typeform.com/PoznajTalenty#source=jobbli&email=${user.email}`;
    dispatch(logout());
  };

  const reconnectTest = () => {
    connectTestWithUserMutation.mutate();
  };

  return (
    <>
      {noDataLoaded && noDataContent ? (
        <div role="alert" className="c-no-data">
          <p>
            <img src={PencilIcon} alt="" /> {noDataContent[0]}
          </p>
          <p>
            {noDataContent[1]} <img src={BriefcaseIcon} alt="" />
          </p>
          {type === "raport" && (
            <button
              className="c-btn c-btn--small-size c-btn--blue-color c-btn--100-w"
              onClick={reconnectTest}
            >
              Połącz ponownie raport z kontem
            </button>
          )}
          <button
            className="c-btn c-btn--small-size c-btn--blue-color c-btn--100-w"
            onClick={goToTest}
          >
            Wypełnij test
          </button>
        </div>
      ) : (
        <div role="alert" className="c-loader">
          <img src={LoadingIcon} alt="" />
          <p>{content}</p>
        </div>
      )}
    </>
  );
};

export default Loader;
