import React, { useEffect } from "react";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import axios from "axios";

import {
  userLoaded,
  setUserChatbotMode,
  authError,
  setUserIsPaid,
  setUserHasAccessToDashboard,
} from "./store/user";
import {
  setUserRiasecInfo,
  setUserRiasecFirstProfileName,
  setUserRiasecSecondProfileName,
  setProfileWeakness,
  setRiasecInfo,
} from "./store/report";
import {
  setTotalCareersList,
  setInitialCareersList,
  addMoreCareersToCareersList,
  setInitialFavoriteCareersList,
  setTotalMatchedCareersList,
  setInitialMatchedList,
} from "./store/careers";
import { setTotalSkillsList, setInitialSkillsList } from "./store/skills";

import Login from "./components/Login";
import Signup from "./components/Signup";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import NotFound from "./components/NotFound";
import PaymentStatusInfo from "./components/PaymentStatusInfo";

import Dashboard from "./components/Dashboard";
import Report from "./components/Report";
import CareersList from "./components/CareersList";
import Skills from "./components/Skills";
import JobOffers from "./components/JobOffers";
import JobOfferDetails from "./components/JobOfferDetails";
import Settings from "./components/Settings";
import PublicProfile from "./components/PublicProfile";
import PublicCareerList from "./components/PublicCareerList";

import "./styles/App.css";

const CHATBOT_MODES = {
  CHAT: "chatbot",
  "Q&A": "qa",
  DISABLED: null,
};

const RIASEC_PROFILE_PL_NAMES = {
  R: "Przedmiotowy",
  I: "Innowacyjny",
  A: "Kreatywny",
  S: "Społeczny",
  E: "Liderski",
  C: "Metodyczny",
};

const RIASEC_PROFILE_EN_NAMES = {
  R: "realistic",
  I: "investigative",
  A: "artistic",
  S: "social",
  E: "enterprising",
  C: "conventional",
};

const App = () => {
  const dispatch = useDispatch();
  const { riasecProfile } = useSelector((state) => state.report);
  const { hasAccessToDashboard, user } = useSelector((state) => state.user);

  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const token = localStorage.getItem("token");

  const { refetch } = useQuery({
    queryKey: ["user"],
    queryFn: async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/users/me?populate[ab_features][populate]`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;

          dispatch(userLoaded({ user: data }));
          dispatch(
            setUserChatbotMode(CHATBOT_MODES[data.ab_features.chat_mode])
          );
        })
        .catch((error) => {
          dispatch(authError());
          //console.error("An error occurred:", error.response);
        }),
    enabled: false,
  });

  const connectTestWithUserMutation = useMutation({
    mutationFn: async () => {
      return await axios.post(
        `${process.env.REACT_APP_API_URL}/api/typeform/connect`,
        { token: localStorage.getItem("testToken") },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      );
    },
    onSuccess: () => {
      localStorage.removeItem("testToken");
      queryClient.invalidateQueries();
    },
  });

  const {} = useQuery({
    queryKey: ["userRiasecInfo"],
    queryFn: async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/users/me?populate=*`, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then(async (response) => {
          dispatch(setUserRiasecInfo(response.data));

          const profileName = response.data?.riasec_profile.split("");

          dispatch(
            setUserRiasecFirstProfileName(
              RIASEC_PROFILE_PL_NAMES[profileName[0]]
            )
          );
          dispatch(
            setUserRiasecSecondProfileName(
              RIASEC_PROFILE_PL_NAMES[profileName[1]]
            )
          );

          const profileWeakness = response.data?.weaknesses;
          dispatch(setProfileWeakness(profileWeakness));

          await axios
            .get(`${process.env.REACT_APP_API_URL}/api/riasec-info`, {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            })
            .then((response) => {
              dispatch(
                setRiasecInfo([
                  response.data.data.attributes[
                    RIASEC_PROFILE_EN_NAMES[profileName[0]]
                  ],
                  response.data.data.attributes[
                    RIASEC_PROFILE_EN_NAMES[profileName[1]]
                  ],
                ])
              );
            })
            .catch((error) => {
              //console.error("An error occurred:", error.response);
            });
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        }),
    enabled: !!token,
  });

  const {} = useQuery({
    queryKey: ["checkUserHasPaid"],
    queryFn: async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/payment/is_paid`, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          const data = response.data;

          dispatch(setUserIsPaid(data.paid));
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        }),
    enabled: !!token,
  });

  const {} = useQuery({
    queryKey: ["checkUserHasAccessToDashboard", user?.id],
    queryFn: async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/coupons/campaign-owner/${user?.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;

          dispatch(
            setUserHasAccessToDashboard(data?.length > 0 ? true : false)
          );
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        }),
    enabled: !!token && !!user?.id,
  });

  const {} = useQuery({
    queryKey: ["initialSkillsList"],
    queryFn: async () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/users/mySkills`, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          dispatch(setTotalSkillsList(response.data.length));
          dispatch(setInitialSkillsList(response.data));
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        });
    },
    enabled: !!token,
  });

  const {} = useQuery({
    queryKey: ["totalCareersListCount"],
    queryFn: async () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/careers`, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          dispatch(setTotalCareersList(response.data.meta.pagination.total));
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        });
    },
    enabled: !!token,
  });

  const {} = useQuery({
    queryKey: ["initialCareersList"],
    queryFn: async () => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/careers?populate=*&pagination[page]=1&pagination[pageSize]=100`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then(async (response) => {
          dispatch(setInitialCareersList(response.data.data));

          if (response.data.meta.pagination.pageCount > 1) {
            for (
              let page = 2;
              page <= response.data.meta.pagination.pageCount;
              page++
            ) {
              await axios
                .get(
                  `${process.env.REACT_APP_API_URL}/api/careers?populate=*&pagination[page]=${page}&pagination[pageSize]=100`,
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.token}`,
                    },
                  }
                )
                .then((response) => {
                  dispatch(addMoreCareersToCareersList(response.data.data));
                })
                .catch((error) => {
                  //console.error("An error occurred:", error.response);
                });
            }
          }
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        });
    },
    enabled: !!token,
  });

  const {} = useQuery({
    queryKey: ["initialFavoriteCareersList"],
    queryFn: async () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/careers/fav?populate=*`, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          dispatch(setInitialFavoriteCareersList(response.data));
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        });
    },
    enabled: !!token,
  });

  const {} = useQuery({
    queryKey: ["matchedCareersList", riasecProfile],
    queryFn: async () => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/careers/matched?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          dispatch(setTotalMatchedCareersList(response.data.total_careers));
          dispatch(setInitialMatchedList(response.data.data));
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        });
    },
    enabled: !!token && !!riasecProfile,
  });

  useEffect(() => {
    if (window.location.href.indexOf("oauth-signin") > -1) {
      localStorage.setItem("token", window.location.href.split("#")[1]);
      refetch();
      return;
    }

    if (token) {
      refetch();
    }
  }, [token, refetch]);

  useEffect(() => {
    if (token && localStorage.getItem("testToken")) {
      connectTestWithUserMutation.mutate();
    }
  }, [token, localStorage.getItem("testToken")]);

  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route path="/profile/:code" element={<PublicProfile />} />
      <Route
        path="/profile/careers-list/:code"
        element={<PublicCareerList />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/oauth-signin" element={<Login />} />
      <Route path="/sign-up" element={<Signup />} />
      <Route path="/login/from-typeform" element={<Login />} />
      <Route path="/sign-up/from-typeform" element={<Signup />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword code={code} />} />
      <Route
        path="/report"
        element={!token ? <Navigate to="/" replace /> : <Report />}
      />
      <Route
        path="/careers-list"
        element={!token ? <Navigate to="/" replace /> : <CareersList />}
      />
      <Route
        path="/skills"
        element={!token ? <Navigate to="/" replace /> : <Skills />}
      />
      <Route
        path="/job-offers"
        element={!token ? <Navigate to="/" replace /> : <JobOffers />}
      />
      <Route
        path="/job-offers/:id"
        element={!token ? <Navigate to="/" replace /> : <JobOfferDetails />}
      />
      <Route
        path="/settings"
        element={!token ? <Navigate to="/" replace /> : <Settings />}
      />
      <Route
        path="/dashboard"
        element={
          !token ? (
            <Navigate to="/" replace />
          ) : hasAccessToDashboard ? (
            <Dashboard />
          ) : (
            <Report />
          )
        }
      />
      <Route
        path="/payment-success"
        element={<PaymentStatusInfo type={"success"} />}
      />
      <Route
        path="/payment-cancelled"
        element={<PaymentStatusInfo type={"cancelled"} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
